var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      staticStyle: { overflow: "auto", "padding-bottom": "53px" },
      attrs: {
        title: _vm.getTitle,
        maskClosable: true,
        width: "650",
        placement: "right",
        closable: true,
        visible: _vm.visible,
      },
      on: { close: _vm.close },
    },
    [
      _vm.editType == "new" || _vm.editType == "edit"
        ? _c(
            "div",
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "dataForm",
                    attrs: {
                      layout: "horizontal",
                      model: _vm.formData,
                      rules: _vm.rules,
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "角色名称", prop: "roleName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入角色名称" },
                        model: {
                          value: _vm.formData.roleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "roleName", $$v)
                          },
                          expression: "formData.roleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "角色编码", prop: "roleCode" } },
                    [
                      _c("a-input", {
                        staticClass: "w-full",
                        attrs: { placeholder: "请输入角色编码" },
                        model: {
                          value: _vm.formData.roleCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "roleCode", $$v)
                          },
                          expression: "formData.roleCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "父级角色", prop: "parentRoleCode" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择父级角色" },
                          model: {
                            value: _vm.formData.parentRoleCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "parentRoleCode", $$v)
                            },
                            expression: "formData.parentRoleCode",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "" } }, [
                            _vm._v("无"),
                          ]),
                          _vm._l(_vm.roleList, function (i) {
                            return _c("a-select-option", { key: i.roleCode }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(i.roleName) +
                                  "\n          "
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "drawer-bootom-button" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-right": "0.8rem" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handleSaveRole },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.editType == "view"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "detail-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "flex jc-end mb-4" },
                  [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "organization:permission:list:button:edit",
                            expression:
                              "'organization:permission:list:button:edit'",
                          },
                        ],
                        staticClass: "mr-3",
                        attrs: { type: "primary" },
                        on: { click: _vm.editRole },
                      },
                      [_vm._v("编辑权限")]
                    ),
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "organization:permission:list:button:edit",
                            expression:
                              "'organization:permission:list:button:edit'",
                          },
                        ],
                        staticClass: "mr-3",
                        attrs: { icon: "edit", type: "primary" },
                        on: { click: _vm.editForm },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _vm._t("default"),
              ],
              2
            ),
            _c(
              "div",
              {},
              [
                _c(
                  "info-row",
                  {
                    attrs: { "label-width": _vm.labelWidth, label: "角色名称" },
                  },
                  [_vm._v(_vm._s(_vm.formData.roleName))]
                ),
                _c(
                  "info-row",
                  {
                    attrs: { "label-width": _vm.labelWidth, label: "角色编码" },
                  },
                  [_vm._v(_vm._s(_vm.formData.roleCode))]
                ),
                _c(
                  "info-row",
                  {
                    attrs: { "label-width": _vm.labelWidth, label: "父级角色" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.getParentRoleName(_vm.formData.parentRoleCode))
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.editType == "role"
        ? _c(
            "div",
            [
              _c(
                "a-form",
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: `【${_vm.formData.roleName}】所拥有的权限`,
                      },
                    },
                    [
                      _c("a-tree", {
                        attrs: {
                          checkable: "",
                          checkedKeys: _vm.checkedKeys,
                          treeData: _vm.treeData,
                          selectedKeys: _vm.selectedKeys,
                          checkStrictly: _vm.checkStrictly,
                        },
                        on: {
                          check: _vm.onCheck,
                          expand: _vm.onExpand,
                          select: _vm.onTreeNodeSelect,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "hasDatarule",
                              fn: function ({ slotTitle, ruleFlag }) {
                                return _c(
                                  "span",
                                  {},
                                  [
                                    _vm._v(
                                      "\n            " + _vm._s(slotTitle)
                                    ),
                                    ruleFlag
                                      ? _c("a-icon", {
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "red",
                                          },
                                          attrs: { type: "align-left" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          1328075695
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "drawer-bootom-button" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-right": "0.8rem" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-right": "0.8rem" },
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        ghost: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit(false)
                        },
                      },
                    },
                    [_vm._v("仅保存")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit(true)
                        },
                      },
                    },
                    [_vm._v("保存并关闭")]
                  ),
                ],
                1
              ),
              _c("role-datarule-modal", { ref: "datarule" }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }