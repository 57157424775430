<template>
  <a-drawer
    :title="getTitle"
    :maskClosable="true"
    width="650"
    placement="right"
    :closable="true"
    @close="close"
    :visible="visible"
    style="overflow: auto; padding-bottom: 53px"
  >
    <div v-if="editType == 'new' || editType == 'edit'">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="角色名称" prop="roleName">
          <a-input v-model="formData.roleName" placeholder="请输入角色名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="角色编码" prop="roleCode">
          <a-input v-model="formData.roleCode" placeholder="请输入角色编码" class="w-full" />
        </a-form-model-item>
        <a-form-model-item label="父级角色" prop="parentRoleCode">
          <a-select placeholder="请选择父级角色" v-model="formData.parentRoleCode">
            <a-select-option :value="''">无</a-select-option>
            <a-select-option v-for="i in roleList" :key="i.roleCode">
              {{ i.roleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>

      <div class="drawer-bootom-button">
        <a-button style="margin-right: 0.8rem" @click="close">取消</a-button>
        <a-button @click="handleSaveRole" type="primary" :loading="loading">保存</a-button>
      </div>
    </div>
    <div v-if="editType == 'view'">
      <div class="detail-wrap">
        <div class="flex jc-end mb-4">
          <a-button type="primary" class="mr-3" @click="editRole" v-has="'organization:permission:list:button:edit'"
            >编辑权限</a-button
          >
          <a-button icon="edit" type="primary" class="mr-3" @click="editForm" v-has="'organization:permission:list:button:edit'"
            >编辑</a-button
          >
        </div>
        <slot></slot>
      </div>
      <div class="">
        <info-row :label-width="labelWidth" label="角色名称">{{ formData.roleName }}</info-row>
        <info-row :label-width="labelWidth" label="角色编码">{{ formData.roleCode }}</info-row>
        <info-row :label-width="labelWidth" label="父级角色">{{ getParentRoleName(formData.parentRoleCode) }}</info-row>
      </div>
    </div>
    <div v-if="editType == 'role'">
      <a-form>
        <a-form-item :label="`【${formData.roleName}】所拥有的权限`">
          <a-tree
            checkable
            @check="onCheck"
            :checkedKeys="checkedKeys"
            :treeData="treeData"
            @expand="onExpand"
            @select="onTreeNodeSelect"
            :selectedKeys="selectedKeys"
            
            :checkStrictly="checkStrictly"
          ><!-- :expandedKeys="expandedKeysss" -->
            <span slot="hasDatarule" slot-scope="{ slotTitle, ruleFlag }">
              {{ slotTitle }}<a-icon v-if="ruleFlag" type="align-left" style="margin-left: 5px; color: red"></a-icon>
            </span>
          </a-tree>
        </a-form-item>
      </a-form>

      <div class="drawer-bootom-button">
        <!-- <a-dropdown style="float: left" :trigger="['click']" placement="topCenter">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="switchCheckStrictly(1)">父子关联</a-menu-item>
            <a-menu-item key="2" @click="switchCheckStrictly(2)">取消关联</a-menu-item>
            <a-menu-item key="3" @click="checkALL">全部勾选</a-menu-item>
            <a-menu-item key="4" @click="cancelCheckALL">取消全选</a-menu-item>
            <a-menu-item key="5" @click="expandAll">展开所有</a-menu-item>
            <a-menu-item key="6" @click="closeAll">合并所有</a-menu-item>
          </a-menu>
          <a-button> 树操作 <a-icon type="up" /> </a-button>
        </a-dropdown> -->
        <!-- <a-popconfirm title="确定放弃编辑？" @confirm="close" okText="确定" cancelText="取消"> -->
        <a-button style="margin-right: 0.8rem" @click="close">取消</a-button>
        <!-- </a-popconfirm> -->
        <a-button @click="handleSubmit(false)" type="primary" :loading="loading" ghost style="margin-right: 0.8rem"
          >仅保存</a-button
        >
        <a-button @click="handleSubmit(true)" type="primary" :loading="loading">保存并关闭</a-button>
      </div>

      <role-datarule-modal ref="datarule"></role-datarule-modal>
    </div>
  </a-drawer>
</template>
<script>
import { queryTreeListForRole, queryRolePermission, saveRolePermission } from '@/api/api'
import { addRole, editRole, duplicateCheck } from '@/api/api'
import RoleDataruleModal from './RoleDataruleModal.vue'
import editor from '@toast-ui/editor'

export default {
  name: 'RoleModal',
  components: {
    RoleDataruleModal,
  },
  data() {
    return {
      roleId: '',
      treeData: [],
      defaultCheckedKeys: [],
      checkedKeys: [],
      expandedKeysss: [],
      allTreeKeys: [],
      autoExpandParent: true,
      checkStrictly: true,
      title: '角色权限配置',
      visible: false,
      loading: false,
      selectedKeys: [],
      editType: 'new',
      labelWidth: '80',
      layout: {
        labelCol: {
          style: 'width: 80px',
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      formData: {},
      roleList: [],
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleCode: [{ required: true, message: '请输入角色编码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    onTreeNodeSelect(id) {
      if (id && id.length > 0) {
        this.selectedKeys = id
      }
      this.$refs.datarule.show(this.selectedKeys[0], this.roleId)
    },
    onCheck(o) {
      if (this.checkStrictly) {
        this.checkedKeys = o.checked
      } else {
        this.checkedKeys = o
      }
    },
    show(roleId, editType, data, roleList) {
      this.roleId = roleId
      this.visible = true
      this.editType = editType
      this.formData = Object.assign({}, data)
      this.roleList = roleList
    },
    editRole() {
      this.editType = 'role'
    },
    editForm() {
      this.editType = 'edit'
    },
    close() {
      this.reset()
      this.$emit('close')
      this.visible = false
    },
    onExpand(expandedKeys) {
      this.expandedKeysss = expandedKeys
      this.autoExpandParent = false
    },
    reset() {
      this.expandedKeysss = []
      this.checkedKeys = []
      this.defaultCheckedKeys = []
      this.loading = false
    },
    expandAll() {
      this.expandedKeysss = this.allTreeKeys
    },
    closeAll() {
      this.expandedKeysss = []
    },
    checkALL() {
      this.checkedKeys = this.allTreeKeys
    },
    cancelCheckALL() {
      //this.checkedKeys = this.defaultCheckedKeys
      this.checkedKeys = []
    },
    switchCheckStrictly(v) {
      if (v == 1) {
        this.checkStrictly = false
      } else if (v == 2) {
        this.checkStrictly = true
      }
    },
    handleCancel() {
      this.close()
    },
    handleSaveRole() {
      const that = this
      // 触发表单验证
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          that.loading = true
          let formData = this.formData
          let obj
          if (!this.formData.id) {
            obj = addRole(formData)
          } else {
            obj = editRole(formData)
          }
          obj
            .then((res) => {
              if (res.success) {
                that.$emit('refreshList')
                that.$message.success(res.message)
              } else {
                that.$message.warning(res.message)
              }
            })
            .finally(() => {
              that.loading = false
              that.close()
            })
        }
      })
    },
    handleSubmit(exit) {
      let that = this
      let params = {
        roleId: that.roleId,
        permissionIds: that.checkedKeys.join(','),
        lastpermissionIds: that.defaultCheckedKeys.join(','),
      }
      that.loading = true
      console.log('请求参数：', params)
      saveRolePermission(params).then((res) => {
        if (res.success) {
          that.$message.success(res.message)
          that.loading = false
          if (exit) {
            that.close()
          }
        } else {
          that.$message.error(res.message)
          that.loading = false
          if (exit) {
            that.close()
          }
        }
        this.loadData()
      })
    },
    loadData() {
      queryTreeListForRole().then((res) => {
        this.treeData = res.data.treeList
        this.allTreeKeys = res.data.ids
        queryRolePermission({ roleId: this.roleId }).then((res) => {
          this.checkedKeys = [...res.data]
          this.defaultCheckedKeys = [...res.data]
          this.expandedKeysss = this.allTreeKeys
          // console.log(this.defaultCheckedKeys)
        })
      })
    },
    getParentRoleName(roleCode) {
      for (let i = 0; i < this.roleList.length; i++) {
        if (this.roleList[i].roleCode == roleCode) {
          return this.roleList[i].roleName
        }
      }
      return '无'
    },
  },
  computed: {
    getTitle() {
      switch (this.editType) {
        case 'new':
          return '新建'
        case 'edit':
          return '编辑'
        case 'role':
          return '角色管理'
        default:
          return '编辑'
      }
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.loadData()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.drawer-bootom-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>